import { MotionImage } from "components/MotionImage";
import { Page } from "components/Page";
import { PageHeader } from "components/PageHeader";
import { PageTitle } from "components/PageTitle";
import { motion, MotionConfigContext } from "framer-motion";
import React from "react";

const variants = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
      delay: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
};

const paragraphs = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 1 },
  },
  hidden: {
    opacity: 0,
    y: 0,
  },
};

export default function AboutPage() {
  return (
    <Page>
      <PageHeader imageSrc="/about-hero-02.jpg" />
      <motion.div
        initial="hidden"
        animate="visible"
        variants={variants}
        className="container lg:max-w-4xl mx-auto"
      >
        <motion.p variants={paragraphs} className="mt-16">
          We are UncleTEO Promotions, an innovative, meticulous and creative
          events company based in Leicester.
        </motion.p>

        <motion.p variants={paragraphs}>
          We organise some of the largest and most renowned urban events in the
          Midlands. Our events are driven with the consumer in mind. We believe
          in making sure the customer is happy by providing unique urban
          experiences consistently.
        </motion.p>
        <motion.p variants={paragraphs}>
          We have hosted artists such as Pop Smoke, Drake, Tory Lanez, Ashanti,
          Nelly, MoStack, D Block Europe, Headie One, B Young and NSG, just to
          name a few.
        </motion.p>
        <motion.p variants={paragraphs}>
          Our events draw a vast number of consumers from cities such as,
          Birmingham, Coventry, London, Kent, Northampton and Loughborough and
          always have amazingly humbling responses from our attendees.
        </motion.p>
        <motion.p variants={paragraphs}>
          We believe our brand is in a strong position in the m arket. The
          consumer trust and have faith in the events we organise and we notice
          this when taking in the atmosphere created at our events.
        </motion.p>
        <motion.p variants={paragraphs}>
          Over the last Calendar year, we have been involved in more than 50
          events, utilising venues such as Blueprint (Leice ster), Club Republic
          (Leicester), The Empire (Coventry) and ATIK (Colchester). Attendee
          numbers at our events range from 500 with our smaller scale events; to
          3200 with our much larger scale events.
        </motion.p>
        <motion.p variants={paragraphs}>
          We believe we would be a great asset to any event; we pr ide ourselves
          in our efficiency, vibrancy, creativity and determination, we always
          go the extra mile and ensure the highest quality of production from
          our team
        </motion.p>

        <div className="mt-16">
          <MotionImage
            src="uncle-teo.jpg"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          />
          <h3 className="mt-6 mb-4">UncleTeo - Founder</h3>
          <motion.p variants={paragraphs}>
            The aim is to always put the consumer first, this is more than just
            an events company, this is a family. We want to keep playing a
            massive part in the consumers partying experience by creating
            "supanatral memories" and defying the presumption that "urban
            events" are a problem. There's no party like an UncleTEOpr party I
            promise you if you attend an event of ours you will surely not
            forget it for the right reasons!
          </motion.p>
        </div>
      </motion.div>
    </Page>
  );
}
